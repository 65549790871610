




















































import { Component, Vue } from "vue-property-decorator";
import { noticeApi } from "../api/NoticeApi";
import { NETWORK_ERROR } from "../router";
import RequestErrorDialog from "./RequestErrorDialog.vue";
import { Contract, userApi } from "../api/UserApi";
import loginModule from "../store/LoginModule";

@Component({
  components: {
    "request-error-dialog": RequestErrorDialog
  }
})
export default class Menu extends Vue {
  BULK_CONTRACT_CODE = 3;

  notice = "";

  dialog = {
    requestError: false
  };

  httpStatus = 500;
  contractsInfoExists = true;

  contracts: Contract[] = [];

  clickMenu(name: string) {
    this.$router.push({ name: name });
  }

  get isContractingBulkDelivery(): boolean {
    if (this.contracts === []) {
      return false;
    }
    const bulkContract = this.contracts.find(
      contract => contract.delivCd === this.BULK_CONTRACT_CODE
    );

    if (bulkContract) {
      return true;
    } else {
      return false;
    }
  }

  async loadAllData(): Promise<void> {
    try {
      const result = {
        contract: await userApi.getBulkContract(loginModule.userId),
        notice: await noticeApi.getNotice()
      };

      this.contracts = result.contract.contractResponse.result;
      this.notice = result.notice.noticeResponse.result.notice;
    } catch (error) {
      this.notice = "";
      this.contracts = [];
      let newError: Error;
      if (error.response) {
        newError = new Error(error.response.status.toString());
      } else {
        newError = new Error(NETWORK_ERROR);
      }
      if (newError.message === "401") {
        // 401 Unauthorized
        this.httpStatus = 401;
        this.dialog.requestError = true;
      } else if (newError.message === "404") {
        // 404 Not Found
        this.httpStatus = 404;
        this.contractsInfoExists = false;
        this.dialog.requestError = true;
      } else if (newError.message === "500") {
        // 500 Internal Server Error
        this.httpStatus = 500;
        this.dialog.requestError = true;
      } else {
        this.httpStatus = parseInt(newError.message);
        // 運用上起こってはいけないエラー
        await this.$router.push({ name: newError.message });
      }
    }
  }

  async mounted() {
    await this.loadAllData();
  }
}
