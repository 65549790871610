import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";

import DefaultLayout from "@/layouts/DefaultLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";

import Login from "@/views/auth/Login.vue";
import Menu from "@/views/Menu.vue";
import BulkDownload from "@/views/BulkDownload.vue";
import PasswordSetting from "@/views/PasswordSetting.vue";
import ManualProviding from "@/views/ManualProviding.vue";

import BadRequest from "@/views/error/BadRequest.vue";
import Deny from "@/views/error/Deny.vue";
import NotFound from "@/views/error/NotFound.vue";
import InternalServerError from "@/views/error/InternalServerError.vue";
import NetworkError from "@/views/error/NetworkError.vue";

import NProgress from "nprogress";
import "nprogress/nprogress.css";

import { SESSION_STORAGE_KEY_TOKEN } from "@/store/LoginModule";

Vue.use(VueRouter);

export const NETWORK_ERROR = "NetworkError";

export const publicRoute: RouteConfig[] = [
  { path: "*", component: NotFound },

  {
    path: "/auth",
    component: AuthLayout,
    meta: { title: "Login" },
    redirect: "/auth/login",
    children: [
      {
        path: "login",
        name: "Login",
        meta: { title: "ログイン" },
        component: Login
      }
    ]
  },

  {
    path: "/400",
    name: "400",
    meta: { title: "Bad Request" },
    component: BadRequest
  },

  {
    path: "/404",
    name: "404",
    meta: { title: "Not Found" },
    component: NotFound
  },

  {
    path: "/500",
    name: "500",
    meta: { title: "Internal Server Error" },
    component: InternalServerError
  },

  {
    path: "/NetworkError",
    name: NETWORK_ERROR,
    meta: { title: "Network Error" },
    component: NetworkError
  }
];

const protectedRoute: RouteConfig[] = [
  {
    path: "/",
    component: DefaultLayout,
    meta: { title: "Home", group: "apps", icon: "" },
    redirect: "/menu",
    children: [
      {
        path: "menu",
        name: "Menu",
        meta: { title: "Menu", group: "apps", icon: "menu" },
        component: Menu
      },

      {
        path: "403",
        name: "Forbidden",
        meta: { title: "Access Denied", hiddenInMenu: true },
        component: Deny
      }
    ]
  },

  {
    path: "/bulk",
    component: DefaultLayout,
    redirect: "/bulk/download",
    meta: { title: "Bulk", group: "bulk", icon: "" },
    children: [
      {
        path: "download",
        name: "BulkDownload",
        meta: { title: "一括配信ダウンロード" },
        component: BulkDownload
      }
    ]
  },

  {
    path: "/password",
    component: DefaultLayout,
    redirect: "/password/setting",
    meta: { title: "Password", group: "password", icon: "" },
    children: [
      {
        path: "setting",
        name: "PasswordSetting",
        meta: { title: "パスワード設定" },
        component: PasswordSetting
      }
    ]
  },

  {
    path: "/manual",
    component: DefaultLayout,
    redirect: "/manual/providing",
    meta: { title: "Manual", group: "manual", icon: "" },
    children: [
      {
        path: "providing",
        name: "ManualProviding",
        meta: { title: "マニュアル提供" },
        component: ManualProviding
      }
    ]
  }
];

const routes: RouteConfig[] = publicRoute.concat(protectedRoute);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  // ログイン済みかどうか
  // ここでは LoginModule がうまく動作しないので
  // LoginModule内で使用している SessionStorage を直接確認する。
  const token = sessionStorage.getItem(SESSION_STORAGE_KEY_TOKEN);
  const isLoggedIn = token !== null && token !== "";

  // ログイン不要のページかどうか
  const isPublic =
    publicRoute.filter(routeConfig => {
      if (routeConfig.children === undefined) {
        return routeConfig.name === to.name;
      } else {
        return (
          routeConfig.children.filter(routeConfig => {
            return routeConfig.name === to.name;
          }).length > 0
        );
      }
    }).length > 0;

  if (isLoggedIn || isPublic) {
    // ログイン済み または ログイン不要のページ の場合
    next();
  } else {
    // ログインしてない場合
    NProgress.done();
    next({ name: "Login" });
  }
});

router.afterEach((_to: Route, _from: Route) => {
  NProgress.done();
});

export default router;
