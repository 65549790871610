import Vue from "vue";
import Vuex from "vuex";

import { LoginModuleBase } from "@/store/LoginModule";

Vue.use(Vuex);

export interface StoreType {
  loginModule: LoginModuleBase;
}

export default new Vuex.Store<StoreType>({
  strict: process.env.NODE_ENV !== "production"
});
