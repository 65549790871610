













































































import { saveAs } from "file-saver";
import loginModule from "../store/LoginModule";
import { Component, Vue } from "vue-property-decorator";
import { userApi, Contract, Pref, ContractResponse } from "../api/UserApi";
import { NETWORK_ERROR } from "../router";
import RequestErrorDialog from "./RequestErrorDialog.vue";

const dataTypeConst = [
  {
    name: "危機管理",
    code: 1
  },
  {
    name: "簡易カメラ",
    code: 2
  },
  {
    name: "CCTV",
    code: 3
  }
];

@Component({
  components: {
    "request-error-dialog": RequestErrorDialog
  }
})
export default class BulkDownload extends Vue {
  dialog = {
    requestError: false
  };

  httpStatus = 500;
  contractsInfoExists = true;

  downloading = false;

  dataType = 0;
  pref = 0;
  date = "";
  contracts: Contract[] = [];

  dataTypeConst = dataTypeConst;

  clickReturn() {
    this.$router.push({ name: "Menu" });
  }

  public get prefList(): Pref[] {
    const prefList = this.contracts.find(
      contract => contract.dataType === this.dataType
    )?.prefList;
    if (prefList) {
      return prefList;
    } else {
      return [];
    }
  }

  public get dateList() {
    const pref = this.prefList.find(pref => pref.prefCd === this.pref);
    if (pref?.dateList) {
      return pref.dateList;
    } else {
      return [];
    }
  }

  download() {
    const fileList = this.dateList.find(date => date.dateStr === this.date)
      ?.fileList;
    if (fileList) {
      for (const file of fileList) {
        this.fileDownload(file);
      }
    }
  }

  /**
   * 一括配信用ファイル一覧情報を取得
   */
  async loadAllData(): Promise<void> {
    let result: { httpStatus: number; contractResponse: ContractResponse };
    try {
      result = await userApi.getBulkContract(loginModule.userId);
      this.contracts = result.contractResponse.result;
    } catch (error) {
      this.contracts = [];
      let newError: Error;
      if (error.response) {
        newError = new Error(error.response.status.toString());
      } else {
        newError = new Error(NETWORK_ERROR);
      }
      if (newError.message === "401") {
        // 401 Unauthorized
        this.httpStatus = 401;
        this.dialog.requestError = true;
      } else if (newError.message === "404") {
        // 404 Not Found
        this.httpStatus = 404;
        this.contractsInfoExists = false;
        this.dialog.requestError = true;
      } else if (newError.message === "500") {
        // 500 Internal Server Error
        this.httpStatus = 500;
        this.dialog.requestError = true;
      } else {
        this.httpStatus = parseInt(newError.message);
        // 運用上起こってはいけないエラー
        await this.$router.push({ name: newError.message });
      }
    }
  }

  /**
   * ファイルをダウンロード
   */
  async fileDownload(filePath: string) {
    let result: { httpStatus: number; headers: any; responseData: Blob };
    try {
      this.downloading = true;
      result = await userApi.getDownloadData(filePath);

      if (result.responseData && result.httpStatus === 200) {
        const contentDisposition = result.headers["content-disposition"];
        const contentDispositions = contentDisposition.split(";");
        const fileNames = contentDispositions[
          contentDispositions.length - 1
        ].split("=");
        const fileName = fileNames[fileNames.length - 1];
        // ファイル名の前後のダブルクォーテーションを削除
        // eslint-disable-next-line
        const fileNameStr = fileName.replace(/\"/g, "");
        saveAs(result.responseData, fileNameStr);
      }
      this.downloading = false;
    } catch (error) {
      this.downloading = false;
      let newError: Error;
      if (error.response) {
        newError = new Error(error.response.status.toString());
      } else {
        newError = new Error(NETWORK_ERROR);
      }
      if (newError.message === "401") {
        // 401 Unauthorized
        this.httpStatus = 401;
        this.dialog.requestError = true;
      } else if (newError.message === "404") {
        // 404 Not Found
        this.httpStatus = 404;
        this.dialog.requestError = true;
      } else if (newError.message === "500") {
        // 500 Internal Server Error
        this.httpStatus = 500;
        this.dialog.requestError = true;
      } else {
        this.httpStatus = parseInt(newError.message);
        // 運用上起こってはいけないエラー
        await this.$router.push({ name: newError.message });
      }
    }
  }

  async mounted() {
    await this.loadAllData();
  }
}
