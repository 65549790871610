































































































import loginModule from "../store/LoginModule";
import { Component, Vue } from "vue-property-decorator";
import { userApi } from "@/api/UserApi";
import { NETWORK_ERROR } from "@/router";
import RequestErrorDialog from "./RequestErrorDialog.vue";

@Component({
  components: {
    "request-error-dialog": RequestErrorDialog
  }
})
export default class PasswordSetting extends Vue {
  valid = false;

  nowpassword = "";
  nextpassword = "";
  repassword = "";
  dispComplete = false;
  success = false;

  dialog = {
    requestError: false
  };

  httpStatus = 500;
  contractsInfoExists = true;

  clickReturn() {
    this.$router.push({ name: "Menu" });
  }

  closeDispComplete() {
    this.dispComplete = false;
    if (this.success === true) {
      this.$router.push({ name: "Menu" });
    }
  }

  async regist() {
    if (
      this.nextpassword === this.repassword &&
      this.nextpassword.length >= 12 &&
      this.nextpassword.length <= 255
    ) {
      try {
        const result = await userApi.setPassword(
          loginModule.userId,
          this.nowpassword,
          this.nextpassword
        );
        this.httpStatus = result.httpStatus;

        if (this.httpStatus === 200) {
          this.success = true;
          this.dispComplete = true;
        }
      } catch (error) {
        let newError: Error;
        if (error.response) {
          newError = new Error(error.response.status.toString());
        } else {
          newError = new Error(NETWORK_ERROR);
        }
        if (newError.message === "400") {
          // 400 Bad Request
          this.httpStatus = 400;
          this.success = false;
          this.dispComplete = true;
        } else if (newError.message === "401") {
          // 401 Unauthorized
          this.httpStatus = 401;
          this.dialog.requestError = true;
        } else if (newError.message === "404") {
          // 404 Not Found
          this.httpStatus = 404;
          this.contractsInfoExists = false;
          this.dialog.requestError = true;
        } else if (newError.message === "500") {
          // 500 Internal Server Error
          this.httpStatus = 500;
          this.dialog.requestError = true;
        } else {
          this.httpStatus = parseInt(newError.message);
          // 運用上起こってはいけないエラー
          await this.$router.push({ name: newError.message });
        }
      }
    } else {
      this.success = false;
      this.dispComplete = true;
    }
  }
}
