import axios, { AxiosRequestConfig } from "axios";
import { ApiResponse } from "@/api/ApiResponse";
import { config } from "@/config/config";
import loginModule from "@/store/LoginModule";

export interface UserResponse extends ApiResponse {
  result: User;
}

export interface ContractResponse extends ApiResponse {
  result: Contract[];
}

export interface PrefListResponse extends ApiResponse {
  result: Pref[];
}

export interface User {
  userId: string;
  userName: string;
  password: string;
  note: string;
}

export interface Contract {
  delivCd: number;
  dataType: number;
  prefList: Pref[];
}

export interface Pref {
  prefCd: number;
  prefName: string;
  dateList: DateObj[];
}

interface DateObj {
  dateStr: string;
  fileList: string[];
}

class UserApi {
  API_SERVER = config.api.access_url;

  /**
   * ユーザ編集用 パスワードクリア
   */
  public async setPassword(
    userId: string,
    nowPassword: string,
    nextPassword: string
  ): Promise<{
    httpStatus: number;
    userResponse: UserResponse;
  }> {
    const url = this.API_SERVER + "/api/users/" + userId + "/setPassword";
    const data = JSON.stringify({
      nowPassword: nowPassword,
      nextPassword: nextPassword
    });
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.patch<UserResponse>(url, data, config);
    return { httpStatus: response.status, userResponse: response.data };
  }

  /**
   * 都道府県情報 取得
   */
  public async getBulkContract(
    userId: string
  ): Promise<{
    httpStatus: number;
    contractResponse: ContractResponse;
  }> {
    const url = this.API_SERVER + "/api/users/" + userId + "/bulk";
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      }
    };
    const response = await axios.get<ContractResponse>(url, config);
    return { httpStatus: response.status, contractResponse: response.data };
  }

  /**
   * ダウンロード
   */
  public async getDownloadData(
    filePath: string
  ): Promise<{
    httpStatus: number;
    headers: any;
    responseData: Blob;
  }> {
    const url = this.API_SERVER + "/api/users/download";
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        auth: loginModule.token
      },
      responseType: "blob",
      params: {
        file: filePath
      }
    };
    const response = await axios.get<Blob>(url, config);
    return {
      httpStatus: response.status,
      headers: response.headers,
      responseData: response.data
    };
  }
}

export const userApi = new UserApi();
