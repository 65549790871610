





























import loginModule from "../store/LoginModule";
import { Component, Vue } from "vue-property-decorator";
import { userApi } from "@/api/UserApi";
import { NETWORK_ERROR } from "@/router";

@Component({})
export default class ManualProviding extends Vue {
  manuals = [
    {
      text: "契約者操作画面－操作マニュアル",
      icon: "mdi-folder",
      url: "/manual/user.pdf"
    },
    {
      text: "リアルタイム配信－クライアントアプリ操作マニュアル",
      icon: "mdi-folder",
      url: "/manual/client.pdf"
    },
    {
      text: "一括配信－ダウンロードマニュアル",
      icon: "mdi-folder",
      url: "/manual/bulk.pdf"
    },
    {
      text: "準リアルタイム配信－ダウンロードマニュアル",
      icon: "mdi-folder",
      url: "/manual/sem_real.pdf"
    }
  ];

  clickReturn() {
    this.$router.push({ name: "Menu" });
  }
}
