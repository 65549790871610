









































import loginModule from "../../store/LoginModule";
import { Component, Vue } from "vue-property-decorator";
import { message } from "../../config/config";

@Component
export default class Login extends Vue {
  valid = false;

  userId = "";
  password = "";

  snackbar = false;
  loginErrorMessage = "";

  get loginRules() {
    return [
      (v: string) => !!v || message.getMessageAndReplace("MSG001", "ログインID")
    ];
  }

  async login() {
    if (!this.valid) {
      return;
    }

    let result: boolean;
    try {
      result = await loginModule.login({
        userId: this.userId,
        password: this.password
      });
    } catch (error) {
      if (error.message === "401") {
        // 認証失敗
        this.loginErrorMessage = message.getMessage("MSG002");
        this.snackbar = true;
      } else {
        // 運用上起こってはいけないエラー
        await this.$router.push({ name: error.message });
      }
      return;
    }
    if (result) {
      await this.$router.push({ name: "Menu" });
    }
  }
}
