


















import { Component, Vue, Prop, PropSync } from "vue-property-decorator";

export type DialogText = {
  title: string;
  errorMessage: string;
};

@Component
export default class RequestErrorDialog extends Vue {
  /** ダイアログ表示フラグ */
  @PropSync("requestErrorDialogFlag", { type: Boolean, default: false })
  requestErrorDialog!: boolean;

  /** httpステータスコード */
  @Prop({ type: Number })
  httpStatus!: number;

  /** ダイアログテキスト */
  @Prop({ type: String })
  message!: string;

  /** 契約者情報の存在の有無 */
  @Prop({ type: Boolean })
  contractsInfoExists!: boolean;

  /**
   * ダイアログを閉じて、遷移処理を必要であれば実行する。
   */
  closeDialog(): void {
    this.requestErrorDialog = false;
    if (this.httpStatus === 401) {
      this.$router.push({ name: "Login" });
    } else if (this.httpStatus === 404) {
      if (this.contractsInfoExists === false) {
        this.$router.push({ name: "Login" });
      }
    } else if (this.httpStatus === 500) {
      this.$router.push({ name: "Login" });
    }
  }

  /**
   * エラーダイアログのテキスト
   * @returns {DialogText} ダイアログのテキスト
   */
  get dialogText(): DialogText {
    if (this.httpStatus === 500) {
      return {
        title: "500エラー",
        errorMessage: "サーバエラーです。通信状況をご確認ください。"
      };
    } else if (this.httpStatus === 404) {
      if (this.contractsInfoExists === false) {
        return {
          title: "404エラー",
          errorMessage:
            "契約者様の契約者情報が取得できませんでした。再度、ログインしてください。"
        };
      } else {
        return {
          title: "404エラー",
          errorMessage:
            "ファイルが存在しません。データを選択しなおしてください。"
        };
      }
    } else if (this.httpStatus === 401) {
      return {
        title: "401エラー",
        errorMessage: "権限エラーです。再度、ログインしてください。"
      };
    } else if (this.httpStatus === 409) {
      return {
        title: "409エラー",
        errorMessage: "既にこのデータは登録されています。"
      };
    } else {
      return {
        title: "エラー",
        errorMessage: "エラーが発生しました。"
      };
    }
  }
}
